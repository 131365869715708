.App {
  text-align: center;
}

.App-header {
  background-color: #282c34; /* Example header background color */
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.status-card {
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px auto;
  max-width: 300px;
  text-align: left;
}

.status-indicator {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.status-circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.up {
  background-color: green;
}

.down {
  background-color: red;
}